import { calendar } from '@microsoft/teams-js'
import { Button } from '@mui/material'
import { CoreTypography, LeafIcon } from '@thriveglobal/thrive-web-leafkit'
import React, { ReactElement, memo, useCallback, useMemo } from 'react'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import useTeamsUrl from '../../../../shared/hooks/useTeamsUrl/useTeamsUrl'
import useCalendarEnabled from '../../../hooks/useCalendarEnabled/useCalendarEnabled'

export interface ScheduleContentOnOutlookProps extends JSX.IntrinsicAttributes {
    button?: ReactElement<any, any>
    content: (url: string) => string
    url?: string
    subject: string
    renderMessageAsDeeplink?: boolean
    ariaLabel?: string
    disableTracking?: boolean
    onScheduled?: (scheduleSuccessful: boolean) => void
}

const messages = defineMessages({
    scheduleContent: {
        defaultMessage: 'Schedule meeting through Outlook',
        description: 'Schedule meeting through Outlook'
    }
})

const ScheduleContentOnOutlook: React.FC<ScheduleContentOnOutlookProps> = ({
    button,
    content,
    url,
    subject,
    renderMessageAsDeeplink,
    ariaLabel,
    disableTracking,
    onScheduled
}) => {
    const { formatMessage } = useIntl()
    const calendarEnabled = useCalendarEnabled()
    const { renderedUrl, isUrlValid } = useTeamsUrl(
        url,
        renderMessageAsDeeplink,
        'schedule_on_outlook',
        'outlook',
        disableTracking
    )

    const contentWithUrl = useMemo(
        () => content(renderedUrl),
        [content, renderedUrl]
    )

    const onSchedule = useCallback(() => {
        calendar
            .composeMeeting({
                subject,
                content: `
                <html><body><p>
                    ${contentWithUrl}
                </p>
                </html></body>`
            })
            .then(() => onScheduled?.(true))
            .catch(() => onScheduled?.(false))
    }, [subject, contentWithUrl, onScheduled])

    const scheduleButton = useMemo(
        () =>
            button ? (
                React.cloneElement(button, {
                    onClick: onSchedule
                })
            ) : (
                <Button
                    variant="contained"
                    onClick={onSchedule}
                    endIcon={<LeafIcon icon="microsoft" iconStyle="brands" />}
                    aria-label={
                        ariaLabel
                            ? ariaLabel
                            : formatMessage(messages.scheduleContent)
                    }
                >
                    <CoreTypography customVariant="buttonNormal">
                        <FormattedMessage
                            defaultMessage="Schedule to Outlook"
                            description="Schedule to Outlook button"
                        />
                    </CoreTypography>
                </Button>
            ),
        [button, ariaLabel, onSchedule, formatMessage]
    )

    // if the url provided is not valid and should not be rendered as a deeplink we should hide this component as the content will break.
    // but if a url was never supplied in the first place we can assume the content is all that matters for this meeting
    const inValidInput = useMemo(
        () => !isUrlValid && !renderMessageAsDeeplink && url,
        [isUrlValid, renderMessageAsDeeplink, url]
    )

    if (!calendarEnabled || inValidInput) {
        return null
    }

    return scheduleButton
}

export default memo(ScheduleContentOnOutlook)
