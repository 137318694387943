import { app, calendar } from '@microsoft/teams-js'
import { ButtonProps } from '@mui/material'
import {
    ThriveAppPlatform,
    getPlatform,
    isMobileDeviceTest
} from '@thriveglobal/thrive-web-core'
import {
    CoreTypography,
    LeafExternalLink,
    LeafIcon,
    LoadingButton
} from '@thriveglobal/thrive-web-leafkit'
import React, {
    ReactElement,
    memo,
    useCallback,
    useEffect,
    useMemo
} from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { ScheduleContentPlatforms } from '../../../enums/scheduleContentPlatforms'
import useCalendarEnabled from '../../../hooks/useCalendarEnabled/useCalendarEnabled'
import { useGenerateMeetingDeeplink } from '../../../hooks/useGenerateMeetingDeeplink/useGenerateMeetingDeeplink'
import { useGenerateOutlookWebUrl } from '../../../hooks/useGenerateOutlookWebUrl/useGenerateOutlookWebUrl'

export interface ScheduleContentButtonProps extends ButtonProps {
    subject: string
    body: string
    attendees?: string
    startTime?: string
    endTime?: string
    useRecommendedIcon?: boolean
    platforms?: ScheduleContentPlatforms[]
    onScheduled?: (scheduleSuccessful: boolean) => void
    onRecommendedContent?: (icon: ReactElement, title: string) => void
}

const OUTLOOK_ICON_URL =
    'https://assets.thriveglobal.com/reset/icons/calendars/outlook.png'

const messages = defineMessages({
    outlook: {
        defaultMessage: 'Outlook',
        description: 'Outlook'
    },
    calendar: {
        defaultMessage: 'Teams calendar',
        description: 'Teams calendar'
    }
})

export const ScheduleContentButton = React.forwardRef<
    HTMLButtonElement,
    ScheduleContentButtonProps
>((props, ref) => {
    const {
        subject,
        body,
        startTime,
        endTime,
        useRecommendedIcon,
        platforms = [
            ScheduleContentPlatforms.WEB,
            ScheduleContentPlatforms.TEAMS,
            ScheduleContentPlatforms.OUTLOOK
        ],
        onScheduled,
        onRecommendedContent,
        ...other
    } = props
    const { formatMessage } = useIntl()
    const {
        enabled: calendarEnabled,
        isMsTeams,
        loading
    } = useCalendarEnabled()
    const generateTeamsMeetingDeeplink = useGenerateMeetingDeeplink()
    const generateOutlookWebUrl = useGenerateOutlookWebUrl()

    const { recommendedIcon, recommendedTitle } = useMemo(() => {
        if (calendarEnabled || !isMsTeams) {
            return {
                recommendedIcon: (
                    <img
                        style={{
                            maxHeight: '3rem',
                            objectFit: 'contain'
                        }}
                        src={OUTLOOK_ICON_URL}
                        alt={formatMessage(messages.outlook)}
                    />
                ),
                recommendedTitle: formatMessage(messages.outlook)
            }
        } else {
            return {
                recommendedIcon: (
                    <LeafIcon icon="calendar-lines" iconStyle="light" />
                ),
                recommendedTitle: formatMessage(messages.calendar)
            }
        }
    }, [calendarEnabled, isMsTeams, formatMessage])

    const onCalendarSchedule = useCallback(() => {
        calendar
            .composeMeeting({
                subject,
                content: body
            })
            .then(() => onScheduled?.(true))
            .catch(() => onScheduled?.(false))
    }, [subject, body, onScheduled])

    const onTeamSchedule = useCallback(() => {
        const url = generateTeamsMeetingDeeplink(
            subject,
            body,
            startTime,
            endTime
        )
        app.openLink(url.toString())
            .then(() => onScheduled?.(true))
            .catch(() => onScheduled?.(false))
    }, [
        generateTeamsMeetingDeeplink,
        subject,
        body,
        startTime,
        endTime,
        onScheduled
    ])

    const onSchedule = useCallback(() => {
        if (calendarEnabled) {
            onCalendarSchedule()
        } else {
            onTeamSchedule()
        }
    }, [calendarEnabled, onCalendarSchedule, onTeamSchedule])

    const scheduleButton = useMemo(() => {
        const baseButton = (
            <LoadingButton
                {...other}
                ref={ref}
                loading={loading}
                fixWidth={true}
                {...(useRecommendedIcon ? { startIcon: recommendedIcon } : {})}
            >
                {props.children ? (
                    props.children
                ) : (
                    <CoreTypography customVariant="buttonNormal">
                        {recommendedTitle}
                    </CoreTypography>
                )}
            </LoadingButton>
        )

        return isMsTeams ? (
            React.cloneElement(baseButton, {
                onClick: onSchedule
            })
        ) : (
            <LeafExternalLink
                hideIcon={true}
                underline="none"
                onContinue={() => onScheduled?.(true)}
                externalLink={generateOutlookWebUrl(
                    subject,
                    body,
                    startTime,
                    endTime
                ).toString()}
            >
                {baseButton}
            </LeafExternalLink>
        )
    }, [
        loading,
        ref,
        other,
        subject,
        body,
        endTime,
        startTime,
        props.children,
        recommendedIcon,
        recommendedTitle,
        isMsTeams,
        onSchedule,
        onScheduled,
        generateOutlookWebUrl,
        useRecommendedIcon
    ])

    useEffect(() => {
        onRecommendedContent?.(recommendedIcon, recommendedTitle)
    }, [onRecommendedContent, recommendedIcon, recommendedTitle])

    const outlookPlatformDisabled =
        calendarEnabled && !platforms.includes(ScheduleContentPlatforms.OUTLOOK)
    const teamsPlatformDisabled =
        isMsTeams &&
        !calendarEnabled &&
        !platforms.includes(ScheduleContentPlatforms.TEAMS)
    const webPlatformDisabled =
        !isMsTeams &&
        !calendarEnabled &&
        !platforms.includes(ScheduleContentPlatforms.WEB)
    const mobileSchedulingDisabled =
        !isMsTeams &&
        ([ThriveAppPlatform.ANDROID, ThriveAppPlatform.IOS].includes(
            getPlatform()
        ) ||
            isMobileDeviceTest())

    if (
        !loading &&
        (outlookPlatformDisabled ||
            teamsPlatformDisabled ||
            webPlatformDisabled ||
            mobileSchedulingDisabled)
    ) {
        return null
    }

    return scheduleButton
})

export default memo(ScheduleContentButton)
