import { app as teamsApp } from '@microsoft/teams-js'
import { useEffect, useRef, useState } from 'react'
import { tryInit } from '../../../utils'

export const useIsMsTeams = (defaultValue: boolean | undefined = false) => {
    const firedIsMsTeams = useRef(false)
    const [isMsTeams, setIsMsTeams] = useState<Boolean>(() => defaultValue)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)

    useEffect(() => {
        async function checkIsMsTeams() {
            try {
                await tryInit()
                    .then(() => setIsMsTeams(true))
                    .catch(() => {
                        setError(true)
                        setIsMsTeams(false)
                    })
            } catch (exception) {
                setError(true)
            } finally {
                setLoading(false)
            }
        }

        if (!isMsTeams && !firedIsMsTeams.current) {
            firedIsMsTeams.current = true
            checkIsMsTeams()
        }
    }, [isMsTeams, firedIsMsTeams])

    useEffect(() => {
        if (isMsTeams && !loading) {
            teamsApp.notifyAppLoaded()
            teamsApp.notifySuccess()
        }
    }, [isMsTeams, loading])

    return { isMsTeams, loading, error }
}

export default useIsMsTeams
