export enum ROUTES {
    HOME = '/',
    RESET = '/reset',
    MICROSTEPS_FIRST = '/teams/microstepsfirst',
    CONFIG = '/teams/config',
    SIDE_PANEL = '/teams/sidepanel',
    RESET_STAGE_AUTH_SELECTOR = '/teams/resetstageselector',
    RESET_STAGE_AUTH = '/teams/a/resetstage',
    RESET_STAGE_UNAUTH = '/teams/u/resetstage',
    NOTIFICATIONSETTINGS = '/teams/notification/settings',
    RESET_IFRAME_PLAYER = '/teams/resetiframeplayer',
    RESET_VOTING_SETUP = '/teams/resetvotingsetup',
    RESET_VOTING_STAGE = '/teams/resetvotingstage',
    AUTH_RELAY = '/ms-teams/auth'
}
