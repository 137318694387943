import withFallback from '../../../hooks/withFallback/withFallback'
import ShareContentOnTeams from './ShareContentOnTeams'
import ShareContentOnTeamsBranchLink from './ShareContentOnTeamsBranchLink'

export const ShareContentOnTeamsWithFallback = withFallback(ShareContentOnTeams)
export const ShareContentOnTeamsBranchLinkWithFallback = withFallback(
    ShareContentOnTeamsBranchLink
)

export default ShareContentOnTeams
