import React, { ComponentType } from 'react'
import { ErrorBoundary } from 'react-error-boundary'

export default function withFallback<Props extends JSX.IntrinsicAttributes>(
    Component: ComponentType<Props>
) {
    return (props: Props): JSX.Element => (
        <ErrorBoundary fallback={<></>}>
            <Component {...props} />
        </ErrorBoundary>
    )
}
